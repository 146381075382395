import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import '../css/report.css';
import '../css/ReportPage.css';
import { userAssessmentReportAction } from '../Store/Assessment';
import axios from 'axios';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const BASE_API = 'https://api.careernaksha.com';
// const BASE_API = 'http://localhost:3000';

// export const backendUrl = 'https://api.careernaksha.com';
// const backendUrl = 'http://192.168.2.149:3000';

const Report = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const navigate = useNavigate();
  const { loding, report } = useSelector((state) => state.report);
  const [userData, setUserData] = useState({});
  const [secondCourse, setSecondCourse] = useState('');
  const [reportFetched, setReportFetched] = useState(false);

  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const uid = urlParams.get('uid');
  const dashboardId = urlParams.get('dashboard');

  const [reportPDF, setReportPDF] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [pdfwidth, setPDFWidth] = useState(window.innerWidth * 0.9);

  useEffect(() => {
    if (!uid) {
      setState(false);
    } else {
      setState(true);
    }
  }, []);

  const getDashboardUserData = async () => {
    await axios
      .post(`${BASE_API}/userdetails/getHoroscopeOnDashboard`, {
        id: dashboardId,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            userAssessmentReportAction(
              dashboardId,
              res.data.answerString,
              'dashboard'
            )
          );
        } else if (res.status === 201) {
          navigate({
            pathname: '/assessment',
            search: `?dashboard=${dashboardId}`,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserData = async () => {
    await axios
      .post(`${BASE_API}/userdetails/getVerandaUser`, {
        uid,
      })
      .then(async (res) => {
        if (res.status === 200) {
          if (!res.data.ResponseString) {
            navigate({
              pathname: '/assessment',
              search: `?uid=${uid}`,
            });
          }
          setUserData(res.data);
          dispatch(
            userAssessmentReportAction(
              res.data.Uniqueid,
              res.data.ResponseString,
              'veranda',
              setReportFetched
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendUserUpdateToVeranda = async () => {
    let link = `https://verandahighered.com/api/career-naksha-users-data-update?uid=${uid}&test_initiated=yes&report_generated=yes&course_recommended=${report['card3'][0]}`;
    if (secondCourse && secondCourse !== 'no') {
      link = `https://verandahighered.com/api/career-naksha-users-data-update?uid=${uid}&test_initiated=yes&report_generated=yes&course_recommended=${report['card3'][0]}%2C${secondCourse}`;
    } else if (report['card3'][1]) {
      link = `https://verandahighered.com/api/career-naksha-users-data-update?uid=${uid}&test_initiated=yes&report_generated=yes&course_recommended=${report['card3'][0]}%2C${report['card3'][1]}`;
    }
    await axios
      .get(link)
      .then((res) => {
        if (res.status === 200) console.log('Data sent to Verandahighered');
      })
      .catch((err) => console.log(err));
  };

  const get2ndCourse = async (exp, c1) => {
    if (c1.length > 1) return setSecondCourse('no');
    await axios
      .post(`${BASE_API}/assessment/getSecondCourse`, {
        exp,
        c1: c1[0],
      })
      .then((res) => {
        if (res.status === 200) {
          res.data.course
            ? setSecondCourse(res.data.course)
            : setSecondCourse('no');
        }
      })
      .catch((err) => console.log(err));
  };

  const getPdfReport = async (id) => {
    const past1 = `${report['card1']?.[0]}, ${report['card1']?.[1]}`;
    const past2 = `${report['card1']?.[2]}, ${report['card1']?.[3]}`;
    const present1 = `${report['card2']?.[0]}`;
    const present2 = `${report['card2']?.[1].join(', ')}`;
    const future1 = `${report['card3'][0]}`;
    const future2 =
      (report['card3'][1] || secondCourse) === 'no'
        ? ''
        : report['card3'][1] || secondCourse;
    const interest = report['traits']['interest'].slice(0, 6);
    const wvalues = report['traits']['workValues'].slice(0, 6);
    const wstyle = report['traits']['workStyles'].slice(0, 6);
    const abilities = report['traits']['abilities'].slice(0, 6);
    const skills = report['traits']['skillsData'].slice(0, 6);
    const knowledge = report['traits']['knowledge'].slice(0, 6);

    const response = await axios.post(
      `${BASE_API}/assessment/generatePDF`,
      {
        id,
        past1,
        past2,
        present1,
        present2,
        future1,
        future2,
        interest,
        wvalues,
        wstyle,
        abilities,
        skills,
        knowledge,
      },
      {
        responseType: 'blob',
      }
    );

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setReportPDF(blob);
    }
  };

  useEffect(() => {
    if (uid) getUserData();
    else if (dashboardId) getDashboardUserData();
    else setReportFetched(true);
    return () => {
      setUserData({});
    };
  }, []);

  const { userInfo } = useSelector((state) => state.user);

  const [cardIsOpen, setCardIsOpen] = useState(false);

  window.onscroll = () => {
    if (document.documentElement.scrollTop > 1100) {
      if (!cardIsOpen) {
        setCardIsOpen(true);
      }
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(
        userAssessmentReportAction(
          userData.Uniqueid || userInfo?.['data']?.['_id'],
          userData.ResponseString || localStorage.getItem('responseString'),
          'veranda'
        )
      );
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (uid && reportFetched && report['card3'] && secondCourse) {
      sendUserUpdateToVeranda();
      getPdfReport(uid);
    } else if (!uid && report['card3']) {
      getPdfReport('cn');
    }
  }, [report, secondCourse]);

  useEffect(() => {
    if (userData.Experience && report?.['card3']?.[0] && reportFetched) {
      get2ndCourse(userData.Experience, report['card3']);
    }
  }, [userData, report]);

  // Update width on window resize to ensure responsiveness
  useEffect(() => {
    const handleResize = () => {
      setPDFWidth(window.innerWidth * 0.9); // 90% of the window width
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const traits = {
    1: 'interest',
    2: 'workValues',
    3: 'workStyles',
    4: 'abilities',
    5: 'skillsData',
    6: 'knowledge',
    7: 'workContext',
  };

  const traitsMsg = {
    1: 'Your Interest is a powerful indicator, motivator and dictator of your attitude & desires for career growth. They are innate and acquired based on socio-economic and environmental factors. Based on Holland theory they can be measured and can be interpreted as per RIASEC model.',
    2: 'Your Work Values are important and assesses career success and job satisfaction. Theory of Work Adjustment developed at University of Minnesota utilized by O*NET framework helps in measuring your values and needs in your work environment.',
    3: 'Your Work Styles are personality characteristics of your ability to work with others, your attitude, and your work ethic assessed using various models like Five Factor Model, Big 5, Hogan etc. It identifies your strength to improve your current workplace relationships.',
    4: "Your Abilities are strengths you want to know via standardised methods of assessment. An individual's performance in different work related tasks or situations is evaluated to understand spectrum of abilities like verbal, cognitive, reasoning, quantitative etc.",
    5: 'Your Skills are your expertise, abilities to do certain tasks and this assessment helps in figuring out set of skills that you are good at. This can be helpful at any stage of your career like start, progress and restart/return.',
    6: 'Your Knowledge is acquired understanding of concepts, principles and processes which you can explain, interpret, apply with perspective, empathy & self-knowledge in your choice of work or career or industry domain.',
    7: 'Your Work Context determines psychological and physical conditions under which the work is performed. It helps in understanding your interpersonal, work conditions, job characteristics to know your desires & work settings.',
  };

  if (reportPDF) {
    return (
      <>
        <Document
          className="w-screen absolute top-0 right-0 flex justify-center"
          file={window.URL.createObjectURL(reportPDF)}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
          <Page
            width={pdfwidth}
            pageNumber={1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
          <div className="w-full h-24 bg-white absolute bottom-0"></div>
        </Document>{' '}
        <div className="fixed z-10 bottom-8 w-screen flex justify-center right-0">
          <a
            download="Report.pdf"
            href={reportPDF && window.URL.createObjectURL(reportPDF)}
            className="text-white bg-blue-400 px-4 py-2 rounded-md font-semibold hover:scale-105 transition-all">
            Download
          </a>
        </div>
      </>
    );
  } else if (false) {
    return (
      <div className="w-full flex justify-center overflow-hidden">
        <div className="w-full max-w-[1440px] flex flex-col py-12">
          <h1 className="w-full text-4xl font-bold text-blue-400 underline text-center">
            Report
          </h1>
          <div className="opacity-25 w-full overflow-hidden">
            <img
              className="absolute top-[15%] w-20 left-[10%]"
              src="./img/Home/asset-8.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[45%] w-24 left-[40%]"
              src="./img/Home/asset-3.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[87%] w-20 left-[25%]"
              src="./img/Home/asset-4.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[125%] w-16 left-[85%]"
              src="./img/Home/asset-9.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[160%] w-20 left-[10%]"
              src="./img/Home/asset-7.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[28%] w-20 left-[80%]"
              src="./img/Home/asset-8.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[85%] w-24 left-[30%]"
              src="./img/Home/asset-3.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[107%] w-20 left-[65%]"
              src="./img/Home/asset-4.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[155%] w-16 left-[35%]"
              src="./img/Home/asset-9.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[190%] w-20 left-[40%]"
              src="./img/Home/asset-7.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[208%] w-20 left-[80%]"
              src="./img/Home/asset-8.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[275%] w-24 left-[30%]"
              src="./img/Home/asset-3.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[187%] w-20 left-[65%]"
              src="./img/Home/asset-4.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[175%] w-16 left-[35%]"
              src="./img/Home/asset-9.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[240%] w-20 left-[40%]"
              src="./img/Home/asset-7.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[295%] w-24 left-[30%]"
              src="./img/Home/asset-3.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[307%] w-20 left-[65%]"
              src="./img/Home/asset-4.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[325%] w-16 left-[35%]"
              src="./img/Home/asset-9.svg"
              alt="avatar"
            />
            <img
              className="absolute top-[340%] w-20 left-[40%]"
              src="./img/Home/asset-7.svg"
              alt="avatar"
            />
          </div>
          {/* past  */}
          <div className="flex items-center flex-col lg:flex-row px-6">
            <div className="h-full flex justify-center items-center mt-12 px-8 w-[15rem]">
              <img
                src="./img/Report/Pack Cards_Past.png"
                alt="past"
                className="max-h-[10rem] max-w-full"
              />
            </div>
            <div>
              <h2 className="lg:text-2xl text-xl font-bold mt-8 text-green-400 z-10 w-full text-center lg:text-left">
                A Great Past
              </h2>
              <h3 className="font-semibold mt-1 lg:text-sm text-xs">
                Fantastic Career So Far with Past Achievements
              </h3>
              <p className="mt-2 ml-4 font-semibold text-sm lg:text-base">
                - You are a{' '}
                <span className="underline font-bold text-sm lg:text-base">
                  {report['card1'][0]}, {report['card1'][1]}
                </span>
              </p>
              <p className="mt-2 ml-4 font-semibold text-sm lg:text-base">
                - You are a{' '}
                <span className="underline font-bold text-sm lg:text-base">
                  {report['card1'][2]}
                </span>
                , {report['card1'][3]}
              </p>
            </div>
          </div>
          <hr className="mt-8 bg-gray-700 h-[1px]" />
          {/* present  */}
          <div className="flex items-center flex-col lg:flex-row px-6">
            <div className="h-full flex justify-center items-center mt-12 px-8 w-[15rem]">
              <img
                src="./img/Report/Pack Cards_Present.png"
                alt="past"
                className="max-h-[10rem] max-w-full"
              />
            </div>
            <div>
              <h2 className="lg:text-2xl text-xl font-bold mt-6 text-green-400 z-10 w-full text-center lg:text-left">
                Present Career Fitment
              </h2>
              <h3 className="font-semibold mt-1 lg:text-sm text-xs">
                Based on your scientific assessment your chances of being
                successful are
              </h3>
              <p className="ml-4 font-semibold mt-1 text-sm lg:text-base">
                -{' '}
                <span className="font-bold underline text-sm lg:text-base">
                  {report['card2'][0]}
                </span>
              </p>
              <h3 className="font-semibold mt-2 lg:text-sm text-xs">
                If you learn skills like
              </h3>
              {report['card2'][1].map((n, i) => {
                return (
                  <p
                    key={i}
                    className="ml-4 font-semibold mt-1 text-sm lg:text-base">
                    -{' '}
                    <span className="font-bold underline text-sm lg:text-base">
                      {n}
                    </span>
                  </p>
                );
              })}
              <p className="font-semibold mt-1 lg:text-sm text-xs">
                {' '}
                you can be more successful.
              </p>
            </div>
          </div>
          <hr className="mt-8 bg-gray-700 h-[1px]" />
          {/* future  */}
          <div className="flex items-center flex-col lg:flex-row px-6">
            <div className="h-full flex justify-center items-center mt-8 px-8 w-[15rem]">
              <img
                src="./img/Report/Arts and Communication.png"
                alt="past"
                className="max-h-[8rem] max-w-full"
              />
            </div>
            <div>
              <h2 className="lg:text-2xl text-xl font-bold mt-6 text-green-400 z-10 w-full text-center lg:text-left">
                Your Fantastic Future
              </h2>
              <h3 className="font-semibold mt-1 lg:text-sm text-xs">
                A great future if you pursue courses like
              </h3>
              {report['card3'].map((n, i) => {
                return (
                  <p
                    key={i}
                    className="ml-4 font-semibold mt-1 text-sm lg:text-base">
                    -{' '}
                    <span className="font-bold underline text-sm lg:text-base">
                      {n}
                    </span>
                  </p>
                );
              })}
              {secondCourse && secondCourse !== 'no' && (
                <p className="ml-4 font-semibold mt-1 text-sm lg:text-base">
                  -{' '}
                  <span className="font-bold underline text-sm lg:text-base">
                    {secondCourse}
                  </span>
                </p>
              )}
            </div>
          </div>
          <hr className="mt-8 bg-gray-700 h-[1px]" />

          {/* traits */}
          <h1 className="w-full lg:text-3xl text-2xl font-bold text-gray-500 underline text-center mt-12">
            Know more about you
          </h1>
          {[1, 2, 3, 4, 5, 6].map((n, i) => {
            return (
              <>
                <div
                  key={i}
                  className="flex items-center mt-4 flex-col lg:flex-row px-6 gap-6">
                  <div className="h-full flex justify-center items-center mt-8 px-8 w-[15rem]">
                    <img
                      src={`./img/Report/traits/CN_traits_${n}.png`}
                      alt="past"
                      className="max-h-[12rem]"
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="max-w-5xl text-sm lg:text-base w-full">
                      {traitsMsg[n]}
                    </p>
                    <div className="flex mt-8 font-semibold flex-wrap">
                      {report['traits'][traits[n]]
                        ?.slice(0, 6)
                        .map((value, key) => {
                          return (
                            <p
                              className="bar-skill lg:text-sm text-xs"
                              key={key}>
                              {value}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <hr className=" mt-4 bg-gray-700 h-[1px]" />
              </>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="report-loader">
        <img src="./img/Report/Loader.gif" alt="" />
      </div>
    );
  }
};

export default Report;
